
@media screen and (min-width: 320px) and (max-width: 409px) {
  .Container {
    grid-template-columns: 1fr 320px 1fr !important;
    grid-template-rows: 2fr 120px 60px auto 3fr !important;
  }

  .Title {
    font-size: 48px !important;
  }

  .Subtext {
    font-size: 26px !important;
  }

  .Card {
    width: 300px !important;
    margin-left: 10px !important;
    grid-template-columns: 1fr !important;
    grid-template-rows: fit-content(75px) fit-content(30px) 1fr 35px !important;
    text-align: center;
  }

  .Card-Header {
    font-size: 18px !important;
  }

  .Card-Navbar {
    font-size: 14px !important;
  }

  .Card-Body {
    font-size: 12px !important;
  }

  .Card-Footer {
    font-size: 12px !important;
  }

  .trinity {
    font-size: 10px;
  }

  .rules {
    text-align: left;
    font-size: 8px !important;
  }

  #ggmaps {
    width: 270px !important;
  }
}

@media screen and (min-width: 410px) and (max-width: 700px) {
  .Container {
    grid-template-columns: 1fr 410px 1fr !important;
    grid-template-rows: 2fr 120px 60px auto 3fr !important;
  }

  .Card {
    width: 380px !important;
    margin-left: 15px !important;
    grid-template-columns: 1fr !important;
    grid-template-rows: fit-content(75px) fit-content(30px) 1fr 35px !important;
    text-align: center;
  }

  .Card-Navbar {
    font-size: 18px !important;
  }

  .Card-Header {
    font-size: 24px !important;
  }

  .trinity {
    font-size: 12px;
  }

  .rules {
    text-align: left;
    font-size: 9px !important;
  }

  #ggmaps {
    width: 350px !important;
  }
}

/* Apple (safari/iOS webkit) text too thick. */
@media not all and (min-resolution:.001dpcm)
{ 
  @supports (-webkit-appearance:none) {
    .Card-Header {
        letter-spacing: -1.2px;
    }
  }
}

.Container {
  min-height: 100vh;
  width: 100vw;

  display: grid;
  clip: auto;
  
  color: white;

  background: linear-gradient(150deg, #f0ba61, #f48244, #ef4640);

  grid-template-columns: 1fr 700px 1fr;
  grid-template-rows: 2fr 125px 60px auto 3fr;
  letter-spacing: 0px;

  grid-template-areas: 
  ". . ."
  ". Title ."
  ". Definition ."
  ". Main ."
  ". Footer ."
}

a {
  color: #fdb6a0;
}

a:hover {
  color: #ffe1d7;
}

.Footer {
  grid-area: Footer;

  padding-top: 12px;
  text-decoration: underline;

  font-family: 'Playfair Display', 'serif';
  text-align: center;
  color: #5c122d;
  font-size: 18px;

  transition: font-size 200ms;

  font-variant: small-caps;
  letter-spacing: 0.7px;

  padding-bottom: 20px;
}

.Footer:hover {
  color: #1f040e;
  font-size: 22px;
}

.Title {
  grid-area: Title;

  text-align: center;

  color: white;

  font-size: 64px;
  font-weight: bold;
  letter-spacing: 3px;
  font-family: 'Playfair Display', 'serif';
  text-shadow: -2px -2px 0 #333, 2px -2px 0 #333, -2px 2px 0 #333, 2px 2px 0 #333;
}

.Title > .s-logo {
  display: inline-block;
  width: 120px;
  height: 120px;
}

.Title > .text-logo {
  display: inline-block;
  vertical-align: top;
  padding-top: 24px;
  padding-left: 4px;
}

.Subtext {
  grid-area: Definition;

  text-align: center;

  color: #5c122d;

  font-size: 34px;
  font-family: 'Great Vibes', 'cursive';
  font-style: italic;
}

/* MEDIA QUERY FOR IPAD THICKNESS OF TEXT INCORRECT */
/* IPAD LIKES 550px */

.Card {
  display: grid;
  grid-area: Main;

  height: 100%;
  width: 500px;

  margin-left: 100px;

  background-color: #c13936;
  background: linear-gradient(150deg, #ce3d3a, #b43230);
  color: #eaeaea;

  text-shadow: -0.5px -0.5px 0 #555, 0.5px -0.5px 0 #555, -0.5px 0.5px 0 #555, 0.5px 0.5px 0 #555;

  border-radius: 30px;

  box-shadow: 1px 2px 4px 1px #0007;

  grid-template-columns: 1fr;
  grid-template-rows: fit-content(75px) 30px 1fr 35px;

  grid-template-areas: 
  "header"
  "navbar"
  "card"
  "footer"
}

.Card-Header {
  background-color: #00000020;
  text-align: center;
  display: block;
  grid-area: header;
  border-radius: 30px 30px 0px 0px;
  padding: 10px 15px;
  font-size: 20px;
  font-family: 'Playfair Display', 'serif';
  font-weight: bold;
}

.Card-Navbar {
  text-align: center;
  background-color: #30002010;
  padding: 6px 8px;
}

.Card-Body {
  display: block;
  grid-area: card;
  font-size: 16px;
  font-family: 'Hind', 'sans-serif';
  padding: 10px 15px;
}

.Card-Body .rules {
  font-size: 14px;
}

.Card-Footer {
  display: block;
  grid-area: footer;
  background-color: #00000015;
  border-radius: 0px 0px 20px 20px;
  padding: 8px 15px;
  font-size: 16px;
}

#ggmaps {
  width: 470px;
  height: 300px;
}